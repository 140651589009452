.checkbox-toggle {
	line-height: 18px;
	-moz-box-align: center;
	align-items: center;
	margin-bottom: 8px;
	display: inline-block;
	position: relative;
	white-space: nowrap;
	background: rgba(0, 0, 0, 0.15);
	border-radius: 3em;
	padding: 1px;

	input {
		appearance: none;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		margin: 0px;
		padding: 0px;
		border: medium;
		background: transparent;
		cursor: pointer;
		border-radius: 3em;
	}

	span {
		text-align: center;
		font-size: 12px;
		font-weight: 700;
		line-height: 1;
		cursor: pointer;
		display: inline-block;
		padding: 7px 15px;
		transition: all 100ms ease-out 0s;
		user-select: none;
		border-radius: 3em;
		color: rgba(51, 51, 51, 0.6);
		background: transparent;
	}

	span:first-of-type {
		padding-right: 8px;
	}

	span:last-of-type {
		padding-left: 8px;
	}

	input:checked ~ span:last-of-type, input:not(:checked) ~ span:first-of-type {
		background: rgb(255, 255, 255);
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px;
		color: rgb(51, 51, 51);
		padding: 7px 15px;
	}
}
