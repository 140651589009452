.badge-tag-list {
	.badge-tag {
		height: 35px;
		padding: 0 15px;
		font-size: 1em;
	}

	.btn-tag-remove {
		margin-left: 5px;
		display: inline-block;

		&:hover {
			cursor: pointer;
			color: black;
		}
	}
}
