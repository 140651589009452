.betting-transaction-history-result {
    .math-data-block {
        .icon:hover {
            cursor: pointer;
        }

        .math-data {
            display: none;
        }

        &:hover .math-data {
            display: block;
        }
    }

    .col.data {
        min-width: 100px;
    }
}
