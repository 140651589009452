.sign-in {
	.logo {
		background-image: url("../../assets/images/Blogo3.png")
	}


	p {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	abbr[title],
	abbr[data-original-title] {
		text-decoration: underline;
		-webkit-text-decoration: underline dotted;
		text-decoration: underline dotted;
		cursor: help;
		border-bottom: 0;
		text-decoration-skip-ink: none;
	}

	address {
		margin-bottom: 1rem;
		font-style: normal;
		line-height: inherit;
	}

	ol,
	ul,
	dl {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	ol ol,
	ul ul,
	ol ul,
	ul ol {
		margin-bottom: 0;
	}

	dt {
		font-weight: 700;
	}

	dd {
		margin-bottom: .5rem;
		margin-left: 0;
	}

	blockquote {
		margin: 0 0 1rem;
	}

	b,
	strong {
		font-weight: bolder;
	}

	small {
		font-size: 80%;
	}

	sub,
	sup {
		position: relative;
		font-size: 75%;
		line-height: 0;
		vertical-align: baseline;
	}

	sub {
		bottom: -.25em;
	}

	sup {
		top: -.5em;
	}

	a {
		color: #007bff;
		text-decoration: none;
		background-color: transparent;
	}

	a:hover {
		color: #0056b3;
		text-decoration: underline;
	}

	a:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;
	}

	a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
		color: inherit;
		text-decoration: none;
	}

	a:not([href]):not([tabindex]):focus {
		outline: 0;
	}

	pre,
	code,
	kbd,
	samp {
		font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
		font-size: 1em;
	}

	.login-wrap {
		margin-top: 1px;
		position: relative;
		border-top: 1px hsl(235, 30%, 50%) solid;
		background-color: hsl(235, 5%, 95%) !important;
	}

	pre {
		margin-top: 0;
		margin-bottom: 1rem;
		overflow: auto;
	}

	figure {
		margin: 0 0 1rem;
	}

	img {
		vertical-align: middle;
		border-style: none;
	}

	caption {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		color: #6c757d;
		text-align: left;
		caption-side: bottom;
	}


	label {
		display: inline-block;
		margin-bottom: 0.5rem;
	}

	button {
		border-radius: 0;
	}

	button:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
	}
	/* Show the checkmark when checked */
	.checkbox-wrap input:checked ~ .checkmark:after {
		display: block;
		content: "\f14a";
		font-family: "FontAwesome";
		color: rgba(0, 0, 0, 0.2);
	}
	/* Style the checkmark/indicator */
	.checkbox-primary {
		color: #01d28e;
	}

	.checkbox-primary input:checked ~ .checkmark:after {
		color: #01d28e;
	}

	.btn {
		cursor: pointer;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		font-size: 15px;
		padding: 10px 20px;
	}

	.btn:hover, .btn:active, .btn:focus {
		outline: none;
	}

	.btn.btn-primary {
		font-weight: 600;
		background-color: hsl(235, 50%, 30%) !important;
		border: 1px solid hsl(235, 50%, 80%) !important;
		color: #fff !important;
	}

	.btn.btn-primary:hover {
		border: 1px solid #01d28e !important;
		background-color: transparent !important;
		color: hsl(235, 50%, 30%) !important;
	}

	.btn.btn-primary.btn-outline-primary {
		border: 1px solid #01d28e;
		background: transparent;
		color: #01d28e;
	}

	.btn.btn-primary.btn-outline-primary:hover {
		border: 1px solid transparent;
		background: #01d28e;
		color: #fff;
	}

	.wrap {
		margin-top: 5em;
		width: 100%;
		overflow: hidden;
		background: #fff;
		border-radius: 5px;
		box-shadow: 0px 10px 5px rgba(255, 255, 255, 0.25);

		.logo {
			margin-top: 15px;
			height: 170px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}

	.form-group:not(:last-child) {
		position: relative;
		z-index: 0;
		margin-bottom: 20px !important;
	}

	#hero-decor {
		position: absolute;
		top: 30%;
		letter-spacing: .2em;
		white-space: nowrap;
		z-index: -1;
		font-size: 220px;
		text-align: center;
		opacity: .15;
		-webkit-text-stroke: 4px #075985e6;
		color: rgba(255,255,255, 0.1);
		font-weight: 600;
		overflow: hidden;
		width: 100%;
	}
}
