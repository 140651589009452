.forgot-password {
    form {
        background-color: white;
        padding: 15px;
        margin: 15px 0;
        border-radius: 5px;
    }

    .resend-otp {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: blue;
        }
    }
}
