.date-range-picker {
    .picker-toggle {
        position: absolute;
        z-index: 55;
        right: 1em;
        top: 40%;
        cursor: pointer;
        color: #808080;

        &.open {
            color: black;
        }
    }

    .picker {
        position: absolute;
        z-index: 5;
        left: 1em;
        border: solid 1px #808080;
    }
}
