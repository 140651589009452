.image-upload {
	.drop-zone {
		min-height: 50px;
		border: solid 1px #808080;
		border-radius: 5px;
		text-align: center;
		padding: 10px;
		background-color: #ddd;
		cursor: pointer;

		.icon {
			color: #808080;
		}

		.description {
			font-size: 0.8em;			
		}
	}
}
