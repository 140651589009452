html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block;
}

body {
	margin: 0;
	font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: hsl(235, 50%, 10%) !important;
}

[tabindex="-1"]:focus {
	outline: 0 !important;
}

hr {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

.toggle-password {
	position: absolute;
	top: 50%;
	right: 15px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: rgba(0, 0, 0, 0.3);
}

.form-floating + .toggle-password {
	top: 40%;
	right: 20px;
}

main.public {
	background-color: transparent;
}

main.private {
	background-color: white;
	min-height: 100%;
	position: relative
}

#sidenavAccordion {
	.sb-sidenav-menu-heading {
		cursor: default;
	}

	.nav .nav-link {
		color: white;
		text-transform: capitalize;

		&:hover {
			cursor: pointer;
		}

		&:hover, &.active {
			background-color: var(--bs-green);
		}
	}
}

.datatable-sorter {
	text-decoration: none;
	color: inherit;

	&:hover {
		 cursor: pointer;
		 text-decoration: underline;
	 }
}

textarea.textarea-resizeable {
	min-height: 100px;
	max-height: 400px;
	width: 100%;
}

.row.form-group:not(:last-child) {
	margin-bottom: 10px;
}

.form-label {
	font-weight: 600;
}

.header .page-title {
	color: white;
}

.btn-small {
	max-width: 100px;
}

.text-right {
	text-align: right;
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
	margin-left: 0.7rem;
}

.btn-link:hover {
	cursor: pointer;
}

.green {
	color: var(--bs-green);
}