.system-trace-result {
    .btn-link {
        text-decoration: none;
    }

    .btn-link:hover {
        cursor: pointer;
        text-decoration: underline;
    }



    .col {
        .data {
            width: 100px;
        }

        .module {
            min-width: 100px;
        }

        .date {
            width: 100px;
        }
    }
}
